import { Button } from "@/components/ui/button";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

const constantSuggestedQuestions = {
  Summarise:
    "Summarise the information so far in bullet points, using 150 words or less.",
  Continue: "Continue the analysis in the best way you see fit.",
  Critique:
    "Critique the analysis above, suggest potential improvements or gaps, as well as other areas or questions we should be thinking about.",
};

interface SuggestedQuestionsProps {
  suggestedQuestions: string[];
  processInput: (input: string) => void;
}

interface QuestionItem {
  text: string;
  isGenerated: boolean;
  displayText?: string;
}

export function SuggestedQuestions({
  suggestedQuestions,
  processInput,
}: SuggestedQuestionsProps) {
  const processSuggestedQuestionClick = (
    question: string,
    isGenerated: boolean,
  ) => {
    sendEvent(EventName.SuggestedQuestionSelected, {
      [EventParameter.SuggestedQuestionType]: isGenerated
        ? EventTitle.GeneratedSuggestedQuestion
        : EventTitle.DefaultSuggestedQuestion,
      [EventParameter.SuggestedQuestion]: question,
    });
    processInput(question);
  };

  if (!Array.isArray(suggestedQuestions) || suggestedQuestions.length === 0)
    return null;

  // Combine dynamic and constant questions
  const allQuestions: QuestionItem[] = [
    ...suggestedQuestions.map((q) => ({ text: q, isGenerated: true })),
    ...Object.entries(constantSuggestedQuestions).map(([key, value]) => ({
      text: value,
      displayText: key,
      isGenerated: false,
    })),
  ];

  return (
    <div className="border-t border-gray-100 bg-gray-50/50 px-4 py-3">
      <div className="grid grid-cols-3 gap-2">
        {allQuestions.map((question, index) => (
          <Button
            key={index}
            variant="outline"
            size="sm"
            className="bg-white hover:bg-gray-50 text-xs text-gray-700 border border-gray-200 shadow-sm h-auto min-h-[1.75rem] px-2.5 py-1.5 w-full justify-start whitespace-normal text-left"
            onClick={() =>
              processSuggestedQuestionClick(question.text, question.isGenerated)
            }
          >
            {question.displayText || question.text}
          </Button>
        ))}
      </div>
    </div>
  );
}
