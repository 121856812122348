import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook, PlaybookStepShort } from "../model/Playbook";

export const useCreatePlaybook = () => {
  const {
    data: createdPlaybook,
    call,
    isLoading: isCreating,
    isFailed: isCreateFailed,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const createPlaybook = async (
    title: string,
    steps: PlaybookStepShort[],
    description?: string,
  ) => {
    await call(`/playbook`, "POST", undefined, {
      title,
      description,
      steps,
    });
  };

  return {
    createdPlaybook,
    createPlaybook,
    isCreating,
    isCreateFailed,
  };
};
