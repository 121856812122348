import ReactGA from "react-ga4";
import * as amplitude from "@amplitude/analytics-browser";

export function sendEvent(eventName: EventName, eventParams: EventParams) {
  ReactGA.event(eventName, eventParams);
  amplitude.logEvent(eventName, eventParams);
}

export type EventParams = {
  [key in EventParameter]?: string;
};

export enum EventName {
  Update = "update",
  StepView = "step_view",
  ButtonClick = "button_click",
  ReportLoaded = "report_loaded",
  TemplateSectionEdited = "template_section_edited",
  ExportChatSent = "export_chat_sent",
  ExportChatReportSent = "export_chat_report_sent",
  PdfReportScheduleUpdated = "pdf_report_schedule_updated",
  ChatCreated = "chat_created",
  SuggestedQuestionSelected = "suggested_question_clicked",
}

export enum EventParameter {
  Label = "label",
  Action = "action",
  StepName = "step_name",
  CompanySymbol = "company_symbol",
  TemplateSectionTitle = "title",
  TemplateSectionPrompt = "prompt",
  ChatMode = "chat_mode",
  ChatId = "chat_id",
  SuggestedQuestionType = "suggested_question_type",
  SuggestedQuestion = "suggested_question",
  CompanyCount = "company_count",
  DocumentCount = "document_count",
  TranscriptCount = "transcript_count",
  FileCount = "file_count",
  UrlCount = "url_count",
  UseNews = "use_news",
  UseInternet = "use_internet",
}

export enum EventTitle {
  DeleteAllChats = "Delete all chats",
  DeleteChat = "Delete chat",
  ExampleChat = "Example chat",
  OpenHelp = "Open help",
  OpenSettings = "Open settings",
  OpenStepExplanation = "Open step explanation",
  ShowExplanation = "Show explanation",
  StopChat = "Stop chat",
  TryAgain = "Try again",
  UpdateSettings = "Update settings",
  ChangeMode = "Change mode",
  ChatModeAnalysis = "Analysis",
  ChatModeCompanyDocs = "Company Docs",
  ChatModeGeneric = "Generic",
  ChatModeAnalysisV2 = "Analysis V2",
  ChatModeCompanyDocsV2 = "Company Docs V2",
  ChatModeAskPdf = "Ask PDF",
  GeneratedSuggestedQuestion = "Generated",
  DefaultSuggestedQuestion = "Default",
}
