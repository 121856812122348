import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { MessageSquare, BookOpen } from "lucide-react";

interface ResearchTypeModalProps {
  open: boolean;
  onClose: () => void;
  onSelectType: (type: "chat" | "playbook") => void;
}

export function ResearchTypeModal({
  open,
  onClose,
  onSelectType,
}: ResearchTypeModalProps) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Start New Research</DialogTitle>
          <DialogDescription>
            Choose how you want to begin your research.
          </DialogDescription>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4 py-4">
          <Button
            className="h-32 flex flex-col items-center justify-center space-y-2 p-4"
            variant="outline"
            onClick={() => onSelectType("chat")}
          >
            <MessageSquare className="h-8 w-8" />
            <span className="text-base font-medium">Chat</span>
          </Button>
          <Button
            className="h-32 flex flex-col items-center justify-center space-y-2 p-4"
            variant="outline"
            onClick={() => onSelectType("playbook")}
          >
            <BookOpen className="h-8 w-8" />
            <span className="text-base font-medium">Playbook</span>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
