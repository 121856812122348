import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ConsensusEstimate } from "../../../shared/models/ConsensusEstimates";

interface EstimatesTableProps {
  estimates: ConsensusEstimate[];
  isOnHoverCard?: boolean;
}

export function EstimatesTable({
  estimates,
  isOnHoverCard = true,
}: EstimatesTableProps) {
  const rows = estimates.map((e, i) => (
    <TableRow key={i} className="text-xs">
      <TableCell>{e.estimateType}</TableCell>
      <TableCell>{e.value}</TableCell>
      <TableCell>{e.currency ? e.currency : "-"}</TableCell>
      <TableCell>{e.scale ? e.scale : "-"}</TableCell>
      <TableCell>{e.note ? e.note : "-"}</TableCell>
    </TableRow>
  ));

  return (
    <ScrollArea className={isOnHoverCard ? "h-[300px]" : "h-full"}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <TableHead>Value</TableHead>
            <TableHead>Currency</TableHead>
            <TableHead>Scale</TableHead>
            <TableHead>Note</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>{rows}</TableBody>
      </Table>
    </ScrollArea>
  );
}
