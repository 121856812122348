import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Edit2, Trash2, Eye } from "lucide-react";
import { Playbook } from "../model/Playbook";
import { useDeletePlaybook } from "../api/useDeletePlaybook";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface PlaybookCardv2Props {
  playbook: Playbook;
  isDefault: boolean;
  isEditable?: boolean;
  setActivePlaybook: (
    playbook: Playbook | undefined,
    isDefault: boolean,
  ) => void;
  setIsDefault: (isDefault: boolean) => void;
  onDelete?: () => void;
}

export function PlaybookCardv2({
  playbook,
  isDefault,
  isEditable,
  setActivePlaybook,
  onDelete,
}: PlaybookCardv2Props) {
  const { deletePlaybook, isDeleting } = useDeletePlaybook();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await deletePlaybook(playbook.id);
      setShowDeleteAlert(false);
      onDelete?.();
    } catch (error) {
      console.error("Failed to delete playbook:", error);
    }
  };

  return (
    <>
      <Card key={playbook.id} className="p-6">
        <div className="flex items-start justify-between">
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold text-gray-900">
              {playbook.title}
            </h3>
            <div className="flex items-center gap-2 mt-2">
              <span className="text-gray-500">{playbook.description}</span>
            </div>
          </div>
          <div className="flex items-start gap-2">
            {isDefault ? (
              <Button
                variant="ghost"
                size="sm"
                className="text-gray-600 hover:text-gray-900"
                onClick={() =>
                  navigate(`/playbooks/${playbook.id}/edit?isDefault=true`)
                }
              >
                <Eye className="h-3.5 w-3.5 mr-1.5" />
                View
              </Button>
            ) : (
              isEditable && (
                <>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-gray-600 hover:text-gray-900"
                    onClick={() => navigate(`/playbooks/${playbook.id}/edit`)}
                  >
                    <Edit2 className="h-3.5 w-3.5 mr-1.5" />
                    Edit
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="text-red-500 hover:text-red-600"
                    onClick={() => setShowDeleteAlert(true)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </>
              )
            )}
          </div>
        </div>

        <div className="pt-4 mt-4 border-t">
          <Button
            variant="outline"
            className="w-full"
            onClick={() => setActivePlaybook(playbook, isDefault)}
          >
            Use Playbook
          </Button>
        </div>
      </Card>

      <AlertDialog open={showDeleteAlert} onOpenChange={setShowDeleteAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              playbook.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              {isDeleting ? "Deleting..." : "Delete"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
