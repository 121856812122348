import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { X, Search, Plus, Loader2 } from "lucide-react";
import { CompanyInfo } from "@/shared/components/company-info/models/CompanyInfo";
import { useSearchCompanies } from "@/shared/hooks/useSearchCompanies";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

interface CompanySearchOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCompanies: CompanyInfo[];
  onCompanySelect: (company: CompanyInfo) => void;
  onCompanyRemove: (companyId: number) => void;
}

export function CompanySearchOverlay({
  isOpen,
  onClose,
  selectedCompanies,
  onCompanySelect,
  onCompanyRemove,
}: CompanySearchOverlayProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const { companies, searchCompanies, isLoading, setCompanies } =
    useSearchCompanies();

  useEffect(() => {
    if (!searchQuery) {
      setCompanies(null);
      return;
    }

    const timer = setTimeout(() => {
      searchCompanies(searchQuery);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery("");
      setCompanies(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-2xl w-[800px] h-[80vh] flex flex-col">
        {/* Header */}
        <div className="p-6 border-b">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-xl font-semibold text-gray-900">
              Add Companies
            </h3>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                onClose();
                setSearchQuery("");
              }}
              className="hover:bg-gray-100"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
          <div className="flex items-center gap-2 bg-gray-50 rounded-lg p-2">
            <Search className="h-5 w-5 text-gray-400" />
            <Input
              placeholder="Search by company name or ticker..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="flex-1 border-0 bg-transparent focus-visible:ring-0"
              autoFocus
            />
          </div>
        </div>

        {/* Content - Flex grow to fill space */}
        <div className="flex-1 p-6 overflow-y-auto min-h-0">
          {/* Selected Companies */}
          {selectedCompanies.length > 0 && (
            <div className="mb-6">
              <div className="text-sm font-medium text-gray-900 mb-3">
                Selected Companies
              </div>
              <div className="flex flex-wrap gap-2">
                {selectedCompanies.map((company) => (
                  <div
                    key={company.id}
                    className="flex items-center gap-2 bg-blue-50 text-blue-700 px-3 py-2 rounded-lg text-sm group"
                  >
                    <Avatar className="h-4 w-4">
                      <AvatarImage
                        src={company.logo}
                        alt={company.companyName}
                      />
                      <AvatarFallback className="text-xs">
                        {company.companyName?.[0]}
                      </AvatarFallback>
                    </Avatar>
                    <span>{company.companyName}</span>
                    <span className="text-blue-500">
                      ({company.tickerSymbol})
                    </span>
                    <button
                      onClick={() => onCompanyRemove(company.id)}
                      className="hover:text-blue-900 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <X className="h-3 w-3" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Search Results */}
          {searchQuery.trim() && (
            <>
              <div className="text-sm font-medium text-gray-900 mb-3">
                Search Results
              </div>
              <div className="space-y-2">
                {isLoading ? (
                  <div className="text-center py-4">
                    <Loader2 className="h-6 w-6 animate-spin mx-auto text-gray-400" />
                  </div>
                ) : companies?.length ? (
                  companies.map((company) => (
                    <button
                      key={company.id}
                      onClick={() => onCompanySelect(company)}
                      className="w-full text-left p-3 hover:bg-gray-50 rounded-lg flex items-center justify-between group transition-colors"
                      disabled={selectedCompanies.some(
                        (c) => c.id === company.id,
                      )}
                    >
                      <div className="flex items-center gap-3">
                        <Avatar className="h-6 w-6">
                          <AvatarImage
                            src={company.logo}
                            alt={company.companyName}
                          />
                          <AvatarFallback className="text-xs">
                            {company.companyName?.[0]}
                          </AvatarFallback>
                        </Avatar>
                        <span className="font-medium">
                          {company.companyName}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-gray-500">
                          {company.tickerSymbol}
                        </span>
                        <Plus className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                      </div>
                    </button>
                  ))
                ) : (
                  <div className="text-center text-gray-500 py-4">
                    No companies found
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {/* Footer - Always visible */}
        <div className="p-6 border-t bg-gray-50 flex items-center justify-between">
          <div className="text-sm text-gray-600">
            {selectedCompanies.length} companies selected
          </div>
          <Button onClick={onClose}>Done</Button>
        </div>
      </div>
    </div>
  );
}
