import { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useCreateCompanyDocsV2Chat } from "../../new-chat/api/useCreateCompanyDocsV2Chat";
import { useCreatePlaybookChat } from "../../playbook/api/useCreatePlaybookChat";
import { CompanyInfo } from "@/shared/components/company-info/models/CompanyInfo";
import { CompanyDocumentView } from "@/shared/models/CompanyDocument";
import { CompanyDocumentType } from "@/shared/enums/CompanyDocumentType";
import { useNavigate } from "react-router-dom";
import { Loader2, Wand2 } from "lucide-react";
import { PromptImproverModal } from "../../existing-chat/components/PromptImproverModal";

interface UploadedItem {
  id: number;
  name: string;
  type: "file" | "url";
  size?: string;
  url?: string;
  file?: File;
  status: "uploading" | "complete" | "error";
  error?: string;
}

interface ResearchQuestionModalProps {
  open: boolean;
  onClose: () => void;
  selectedCompanies: CompanyInfo[];
  selectedDocuments: CompanyDocumentView[];
  uploadedItems: UploadedItem[];
  includeNews: boolean;
  includeWeb: boolean;
  researchType?: "chat" | "playbook";
  title?: string;
  playbookId?: string;
  isDefaultPlaybook?: boolean;
}

export function ResearchQuestionModal({
  open,
  onClose,
  selectedCompanies,
  selectedDocuments,
  uploadedItems,
  includeNews,
  includeWeb,
  researchType,
  title,
  playbookId,
  isDefaultPlaybook = false,
}: ResearchQuestionModalProps) {
  const [question, setQuestion] = useState(title || "");
  const [showPromptImprover, setShowPromptImprover] = useState(false);
  const { createChat: createCompanyChat, isLoading: isCompanyChatLoading } =
    useCreateCompanyDocsV2Chat();
  const { createChat: createPlaybookChat, chat: playbookChat } =
    useCreatePlaybookChat();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!question.trim() || !researchType || isCompanyChatLoading) return;

    try {
      if (researchType === "chat") {
        // Extract URLs from uploaded items
        const urls = uploadedItems
          .filter((item) => item.type === "url" && item.url)
          .map((item) => item.url!);

        // Get company IDs and document IDs
        const companyIds = selectedCompanies.map((company) => company.id);

        // Separate documents into filings and transcripts
        const filingIds = selectedDocuments
          .filter((doc) => doc.type === CompanyDocumentType.Filing)
          .map((doc) => doc.id);

        const transcriptIds = selectedDocuments
          .filter((doc) => doc.type === CompanyDocumentType.Transcript)
          .map((doc) => doc.id);

        // Extract files from uploaded items
        const files = uploadedItems
          .filter((item) => item.type === "file" && item.file)
          .map((item) => item.file!);

        console.log("Creating company chat...");
        const response = await createCompanyChat(
          question,
          companyIds,
          filingIds,
          transcriptIds,
          files,
          includeNews,
          includeWeb,
          urls,
        );
        console.log("Create chat response:", response);

        if (response?.id) {
          console.log("Navigating to chat:", response.id);
          onClose(); // Close modal first to prevent state issues
          navigate(`/c/${response.id}`);
          return; // Return early to prevent the second onClose call
        }
      } else if (researchType === "playbook" && playbookId) {
        // Create playbook chat
        console.log("Creating playbook chat...");
        const files = uploadedItems
          .filter((item) => item.type === "file" && item.file)
          .map((item) => item.file!);

        // Separate documents into filings and transcripts
        const filingIds = selectedDocuments
          .filter((doc) => doc.type === CompanyDocumentType.Filing)
          .map((doc) => doc.id);

        const transcriptIds = selectedDocuments
          .filter((doc) => doc.type === CompanyDocumentType.Transcript)
          .map((doc) => doc.id);

        await createPlaybookChat(
          playbookId,
          isDefaultPlaybook,
          question, // Use question as playbook title
          false, // sendPdf
          selectedCompanies.map((c) => c.id),
          filingIds,
          transcriptIds,
          files,
          includeNews,
          includeWeb,
        );

        if (playbookChat?.id) {
          console.log("Navigating to chat:", playbookChat.id);
          onClose();
          navigate(`/c/${playbookChat.id}`);
          return;
        }
      }
    } catch (error) {
      console.error("Failed to create research:", error);
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (title && researchType === "playbook") {
      handleSubmit(new Event("submit") as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const isLoading = isCompanyChatLoading;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px]">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            {researchType === "chat"
              ? "Enter Research Question"
              : "Enter Playbook Title"}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              {(!title || researchType !== "playbook") && (
                <div className="flex gap-2">
                  <Textarea
                    placeholder={
                      researchType === "chat"
                        ? "What would you like to research?"
                        : "Enter a title for your research playbook"
                    }
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    className="flex-1 min-h-[120px] resize-none"
                    autoFocus
                    disabled={isLoading}
                  />
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() =>
                      question.trim() && setShowPromptImprover(true)
                    }
                    className="gap-2 px-3 hover:bg-gray-50 border-gray-200 h-fit"
                    disabled={!question.trim() || isLoading}
                  >
                    <Wand2 className="h-4 w-4 text-gray-600" />
                    <span className="text-sm text-gray-600">Improve</span>
                  </Button>
                </div>
              )}
              <div className="flex justify-end gap-3">
                <Button
                  type="button"
                  variant="outline"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!question.trim() || isLoading || !researchType}
                  className="bg-gray-900 hover:bg-gray-800"
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : researchType === "chat" ? (
                    "Start Research"
                  ) : (
                    "Create Playbook"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>

      <PromptImproverModal
        isOpen={showPromptImprover}
        onClose={() => setShowPromptImprover(false)}
        originalPrompt={question}
        onAccept={(improvedPrompt) => {
          setQuestion(improvedPrompt);
          setShowPromptImprover(false);
        }}
      />
    </Dialog>
  );
}
