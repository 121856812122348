import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { Wand2, Loader2 } from "lucide-react";
import { useImprovePrompt } from "../api/useImprovePrompt";
import { useImprovePlaybookStep } from "@/features/playbook/api/useImprovePlaybookStep";

interface PromptImproverModalProps {
  isOpen: boolean;
  onClose: () => void;
  originalPrompt: string;
  onAccept: (improvedPrompt: string) => void;
  playbookId?: string;
  stepId?: string;
}

export function PromptImproverModal({
  isOpen,
  onClose,
  originalPrompt,
  onAccept,
  playbookId,
  stepId,
}: PromptImproverModalProps) {
  const [improvement, setImprovement] = useState("");
  const [improvedPrompt, setImprovedPrompt] = useState("");
  const { improvePrompt, isLoading } = useImprovePrompt();
  const { improvePlaybookStep, isLoading: isImprovingPlaybookStep } =
    useImprovePlaybookStep();

  const handleImprove = async () => {
    try {
      if (playbookId && stepId) {
        const result = await improvePlaybookStep(
          playbookId,
          stepId,
          improvement,
        );
        if (result) {
          setImprovedPrompt(result);
        }
      } else {
        const result = await improvePrompt(originalPrompt, improvement);
        if (result) {
          setImprovedPrompt(result);
        }
      }
    } catch (error) {
      console.error("Failed to improve prompt:", error);
    }
  };

  const handleClose = () => {
    setImprovement("");
    setImprovedPrompt("");
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Wand2 className="h-5 w-5" />
            Improve Prompt
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          {/* Original Prompt */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-900">
              Original Prompt
            </label>
            <div className="p-3 bg-gray-50 rounded-lg text-gray-700 text-sm whitespace-pre-wrap">
              {originalPrompt}
            </div>
          </div>

          {/* Improvement Direction */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-900">
              How would you like to improve the prompt?
            </label>
            <Textarea
              placeholder="Optional: Add specific aspects you'd like to improve..."
              value={improvement}
              onChange={(e) => setImprovement(e.target.value)}
              className="h-24"
            />
          </div>

          {/* Improved Version */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-900">
                Improved Version
              </label>
              <Button
                variant="outline"
                size="sm"
                onClick={handleImprove}
                disabled={isLoading || isImprovingPlaybookStep}
              >
                {isLoading || isImprovingPlaybookStep ? (
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                ) : (
                  <Wand2 className="h-4 w-4 mr-2" />
                )}
                Generate Improvement
              </Button>
            </div>
            {improvedPrompt ? (
              <div className="p-3 bg-emerald-50 rounded-lg text-emerald-900 text-sm whitespace-pre-wrap">
                {improvedPrompt}
              </div>
            ) : (
              <div className="p-3 border-2 border-dashed border-gray-200 rounded-lg text-gray-500 text-sm text-center">
                Click "Generate Improvement" to see the improved version
              </div>
            )}
          </div>

          {/* Actions */}
          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={handleClose}>
              Use Original
            </Button>
            <Button
              onClick={() => {
                if (improvedPrompt) {
                  onAccept(improvedPrompt);
                  handleClose();
                }
              }}
              disabled={!improvedPrompt || isLoading}
            >
              Use Improved Version
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
