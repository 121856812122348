import { PlaybookStepShort } from "../model/Playbook";

export class GeneratedPlaybook {
  id: string;
  title: string;
  description: string;
  steps: PlaybookStepShort[];

  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.steps = data.steps.map((step: any) => new PlaybookStepShort(step));
  }
}
