import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

interface PlaybookCreatorModalProps {
  open: boolean;
  onClose: () => void;
  onCreateManual: (name: string, description: string) => void;
}

export function PlaybookCreatorModal({
  open,
  onClose,
  onCreateManual,
}: PlaybookCreatorModalProps) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const isValid = name.trim() !== "";

  const handleCreate = () => {
    if (isValid) {
      onCreateManual(name.trim(), description.trim());
    }
  };

  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle>Create New Playbook</DialogTitle>
        </DialogHeader>

        <div className="space-y-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="name">Playbook Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter a name for your playbook"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe what this playbook is for"
              className="min-h-[120px] resize-none"
            />
            <p className="text-sm text-muted-foreground">
              Tip: Include the purpose and goals of this playbook
            </p>
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleCreate} disabled={!isValid}>
            Create Playbook
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
