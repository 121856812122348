import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Chat } from "../../../shared/models/Chat";
import { useCallback, useMemo } from "react";

export const useGetChat = (chatId: string, userId?: string) => {
  const {
    data: chat,
    setData: setChat,
    call,
  } = useApiPrivateRequest<Chat>((response) => new Chat(response));

  // Memoize the API endpoint
  const endpoint = useMemo(() => {
    return `${userId ? "/admin" : ""}/chat/${chatId}`;
  }, [chatId, userId]);

  const getChat = useCallback(async () => {
    await call(endpoint, "GET", { userId });
  }, [call, endpoint, userId]);

  return { chat, setChat, getChat };
};
