import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

interface ImprovePromptRequest {
  prompt: string;
  improvementDescription?: string;
}

export const useImprovePrompt = () => {
  const {
    data: improvedPrompt,
    call,
    isLoading,
  } = useApiPrivateRequest<string>((response) => response);

  const improvePrompt = async (
    prompt: string,
    improvementDescription?: string,
  ) => {
    const request: ImprovePromptRequest = {
      prompt,
      improvementDescription,
    };
    return await call("/generation/improvePrompt", "POST", undefined, request);
  };

  return { improvedPrompt, improvePrompt, isLoading };
};
