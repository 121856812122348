import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface OnboardingVideoProps {
  opened: boolean;
  close: () => void;
}

export default function OnboardingVideo({
  opened,
  close,
}: OnboardingVideoProps) {
  return (
    <Dialog open={opened} onOpenChange={(open) => !open && close()}>
      <DialogContent className="w-[80vw] max-w-[1200px] p-5">
        <DialogHeader>
          <DialogTitle>Quantly Tutorial</DialogTitle>
        </DialogHeader>
        <div className="relative pb-[56.25%] rounded-lg h-0 overflow-hidden">
          {opened && (
            <video
              src="https://assets.quantly-ai.com/exploring.mp4"
              controls
              className="absolute top-0 left-0 w-full h-full"
            >
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
