import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Wand2, PenLine } from "lucide-react";

interface PlaybookCreationTypeModalProps {
  open: boolean;
  onClose: () => void;
  onSelectManual: () => void;
  onSelectGenerate: () => void;
}

export function PlaybookCreationTypeModal({
  open,
  onClose,
  onSelectManual,
  onSelectGenerate,
}: PlaybookCreationTypeModalProps) {
  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle className="text-2xl">Create New Playbook</DialogTitle>
        </DialogHeader>

        <div className="grid grid-cols-2 gap-4 py-6">
          <Button
            onClick={onSelectManual}
            variant="outline"
            className="h-auto py-6 flex flex-col items-center gap-4 hover:border-primary/50"
          >
            <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center">
              <PenLine className="h-6 w-6 text-primary" />
            </div>
            <div className="text-center">
              <div className="font-semibold text-lg mb-2">Create Manually</div>
              <div className="text-sm text-muted-foreground">
                Create a playbook
                <br />
                from scratch
              </div>
            </div>
          </Button>

          <Button
            onClick={onSelectGenerate}
            variant="outline"
            className="h-auto py-6 flex flex-col items-center gap-4 bg-primary hover:bg-primary/90"
          >
            <div className="w-12 h-12 rounded-full bg-white/20 flex items-center justify-center">
              <Wand2 className="h-6 w-6 text-white" />
            </div>
            <div className="text-center">
              <div className="font-semibold text-lg text-white mb-2">
                Generate with AI
              </div>
              <div className="text-sm text-white/80">
                Let AI create
                <br />
                your playbook
              </div>
            </div>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
