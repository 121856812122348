import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Search,
  Plus,
  BookOpen,
  ArrowLeft,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import { Playbook, PlaybookShort } from "../model/Playbook";
import { GeneratedPlaybook } from "../models/GeneratedPlaybook";
import { useGetPlaybooks } from "../api/useGetPlaybooks";
import { useGetDefaultPlaybooks } from "../api/useGetDefaultPlaybooks";
import { useCreatePlaybook } from "../api/useCreatePlaybook";
import { useGeneratePlaybook } from "../api/useGeneratePlaybook";
import { PlaybookCreatorModal } from "./PlaybookCreatorModal";
import { PlaybookGenerateModal } from "./PlaybookGenerateModal";
import { PlaybookCreationTypeModal } from "./PlaybookCreationTypeModal";
import { PlaybookPreviewModal } from "./PlaybookPreviewModal";
import { useNavigate } from "react-router-dom";
import { PlaybookCardv2 } from "./PlaybookCardv2";
import { useToast } from "@/hooks/use-toast";
import { v4 as uuidv4 } from "uuid";

interface PlaybookListProps {
  setActivePlaybook: (
    playbook: Playbook | PlaybookShort | undefined,
    isDefault: boolean,
  ) => void;
  setIsDefault: (isDefault: boolean) => void;
}

export default function PlaybookList({
  setActivePlaybook,
  setIsDefault,
}: PlaybookListProps) {
  const { playbooks, getPlaybooks } = useGetPlaybooks();
  const { defaultPlaybooks, getDefaultPlaybooks } = useGetDefaultPlaybooks();
  const { createPlaybook } = useCreatePlaybook();
  const { generatePlaybook, generatedPlaybook, isGenerating } =
    useGeneratePlaybook();
  const { toast } = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [showCreationTypeModal, setShowCreationTypeModal] = useState(false);
  const [showManualCreator, setShowManualCreator] = useState(false);
  const [showGenerator, setShowGenerator] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [generatedPlaybookData, setGeneratedPlaybookData] =
    useState<GeneratedPlaybook | null>(null);
  const [showAllDefaults, setShowAllDefaults] = useState(false);
  const [showAllCustom, setShowAllCustom] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getPlaybooks();
    getDefaultPlaybooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (generatedPlaybook) {
      setGeneratedPlaybookData(generatedPlaybook);
      setShowGenerator(false); // Only close generator modal after successful generation
      setShowPreview(true);
    }
  }, [generatedPlaybook]);

  const filteredDefaultPlaybooks =
    defaultPlaybooks?.filter((playbook) =>
      playbook.title.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || [];

  const displayedDefaultPlaybooks = showAllDefaults
    ? filteredDefaultPlaybooks
    : filteredDefaultPlaybooks.slice(0, 4);

  const filteredPlaybooks =
    playbooks?.filter((playbook) =>
      playbook.title.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || [];

  const displayedPlaybooks = showAllCustom
    ? filteredPlaybooks
    : filteredPlaybooks.slice(0, 4);
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Header */}
      <div className="bg-white border-b border-gray-100">
        <div className="container mx-auto max-w-7xl px-8 py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => navigate("/")}
                className="hover:bg-gray-100"
              >
                <ArrowLeft className="h-5 w-5" />
              </Button>
              <div className="flex items-center gap-3">
                <BookOpen className="h-6 w-6" />
                <h1 className="text-2xl font-bold text-gray-900">
                  Playbook Manager
                </h1>
              </div>
            </div>
            <Button
              onClick={() => setShowCreationTypeModal(true)}
              className="gap-2"
            >
              <Plus className="h-4 w-4" />
              New Playbook
            </Button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto max-w-7xl px-8 py-12">
        {/* Search */}
        <div className="mb-8">
          <div className="relative w-full max-w-md">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
            <Input
              placeholder="Search playbooks..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-9"
            />
          </div>
        </div>

        {/* Custom Playbooks */}
        {filteredPlaybooks.length > 0 && (
          <div className="mb-12">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-semibold text-gray-900">
                Your Playbooks
              </h2>
              {filteredPlaybooks.length > 4 && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowAllCustom(!showAllCustom)}
                  className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
                >
                  {showAllCustom ? (
                    <>
                      Show Less
                      <ChevronUp className="h-4 w-4" />
                    </>
                  ) : (
                    <>
                      Show All
                      <ChevronDown className="h-4 w-4" />
                    </>
                  )}
                </Button>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {displayedPlaybooks.map((playbook) => (
                <PlaybookCardv2
                  key={playbook.id}
                  playbook={playbook}
                  isDefault={false}
                  onDelete={getPlaybooks}
                  setActivePlaybook={setActivePlaybook}
                  setIsDefault={setIsDefault}
                  isEditable
                />
              ))}
            </div>
          </div>
        )}

        {/* Default Playbooks */}
        {filteredDefaultPlaybooks.length > 0 && (
          <div>
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-semibold text-gray-900">
                Quantly Playbooks
              </h2>
              {filteredDefaultPlaybooks.length > 4 && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowAllDefaults(!showAllDefaults)}
                  className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
                >
                  {showAllDefaults ? (
                    <>
                      Show Less
                      <ChevronUp className="h-4 w-4" />
                    </>
                  ) : (
                    <>
                      Show All
                      <ChevronDown className="h-4 w-4" />
                    </>
                  )}
                </Button>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {displayedDefaultPlaybooks.map((playbook) => (
                <PlaybookCardv2
                  key={playbook.id}
                  playbook={playbook}
                  isDefault={true}
                  setActivePlaybook={setActivePlaybook}
                  setIsDefault={setIsDefault}
                />
              ))}
            </div>
          </div>
        )}

        {/* Empty State */}
        {filteredPlaybooks.length === 0 &&
          filteredDefaultPlaybooks.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500">No playbooks found</p>
            </div>
          )}
      </div>

      {showCreationTypeModal && (
        <PlaybookCreationTypeModal
          open={showCreationTypeModal}
          onClose={() => setShowCreationTypeModal(false)}
          onSelectManual={() => {
            setShowCreationTypeModal(false);
            setShowManualCreator(true);
          }}
          onSelectGenerate={() => {
            setShowCreationTypeModal(false);
            setShowGenerator(true);
          }}
        />
      )}

      {showManualCreator && (
        <PlaybookCreatorModal
          open={showManualCreator}
          onClose={() => setShowManualCreator(false)}
          onCreateManual={async (name, description) => {
            setShowManualCreator(false);
            await createPlaybook(name, [], description);
            await getPlaybooks();
          }}
        />
      )}

      {showGenerator && (
        <PlaybookGenerateModal
          open={showGenerator}
          onClose={() => {
            setShowGenerator(false);
            setGeneratedPlaybookData(null);
          }}
          onGenerate={async (name, purpose) => {
            try {
              await generatePlaybook(name, purpose);
            } catch (_) {
              toast({
                title: "Error",
                description: "Failed to generate playbook. Please try again.",
                variant: "destructive",
              });
            }
          }}
          isGenerating={isGenerating}
        />
      )}

      {showPreview && generatedPlaybookData && (
        <PlaybookPreviewModal
          open={showPreview}
          playbook={generatedPlaybookData}
          onClose={() => {
            setShowPreview(false);
            setShowGenerator(true);
            setGeneratedPlaybookData(null);
          }}
          onConfirm={async () => {
            try {
              const stepsWithIds = generatedPlaybookData.steps.map(
                (step, index) => ({
                  ...step,
                  id: uuidv4(),
                  order: index,
                }),
              );

              await createPlaybook(
                generatedPlaybookData.title,
                stepsWithIds,
                generatedPlaybookData.description,
              );
              setGeneratedPlaybookData(null);
              setShowPreview(false);
              toast({
                title: "Success",
                description: "Playbook created successfully!",
              });
              await getPlaybooks();
            } catch (_) {
              setShowPreview(true);
              toast({
                title: "Error",
                description: "Failed to create playbook. Please try again.",
                variant: "destructive",
              });
            }
          }}
        />
      )}
    </div>
  );
}
