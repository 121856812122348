import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook } from "../model/Playbook";

export const useGetPlaybooks = () => {
  const {
    data: playbooks,
    call,
    isLoading,
    isFailed,
    setData: setPlaybooks,
  } = useApiPrivateRequest<Playbook[]>((response) =>
    response.map((playbook: any) => new Playbook(playbook)),
  );

  const getPlaybooks = async () => {
    await call(`/playbook`, "GET");
  };

  return { playbooks, getPlaybooks, isLoading, isFailed, setPlaybooks };
};
