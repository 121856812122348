import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useGetChat } from "../features/existing-chat/api/useGetChat";
import { Card } from "@/components/ui/card";
import ExistingChat from "../features/existing-chat/ExistingChat";
import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../shared/enums/LocalStorageKeys";
import { FileDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useMetadataResolver } from "@/features/chat-management/hooks/useMetadataResolver";
import type { MetadataCache } from "@/features/chat-management/hooks/useMetadataResolver";
import { BackToStartButton } from "@/components/ui/back-to-start-button";
import { ResearchContext } from "@/components/research-context/ResearchContext";
import { CitationOverview } from "../features/existing-chat/components/CitationOverview";
import { CitationIndex } from "../features/sources/models/Citation";
import { CitationSourceType } from "../shared/enums/CitationSourceType";
import { useSendUserInputV2 } from "../features/existing-chat/api/useSendUserInputV2";
import { AddContextModal } from "../features/chat-management/components/AddContextModal";

export default function Chat() {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { chat, getChat } = useGetChat(chatId || "");
  const [chatTitle, setChatTitle] = useState<string>("");
  const [showAddContext, setShowAddContext] = useState(false);
  const [selectedCitation, setSelectedCitation] =
    useState<CitationIndex | null>(null);
  const [sidebarCitation] = useLocalStorage<string | undefined>({
    key: LocalStorageKeys.SidebarCitation,
    defaultValue: undefined,
  });
  const initialFetchRef = useRef<{ [key: string]: boolean }>({});
  const { resolveMetadata, cache } = useMetadataResolver();
  const [resolvedMetadata, setResolvedMetadata] =
    useState<MetadataCache | null>(null);
  const { sendUserInputV2 } = useSendUserInputV2(chatId || "");

  useEffect(() => {
    if (chatId && chatId !== "new" && !initialFetchRef.current[chatId]) {
      initialFetchRef.current[chatId] = true;
      getChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (chatId === "new") {
      setChatTitle("New Research");
    } else if (chat?.input) {
      setChatTitle(chat.input);
    }
  }, [chatId, chat]);

  useEffect(() => {
    if (sidebarCitation) {
      try {
        const citation = JSON.parse(sidebarCitation);
        setSelectedCitation(citation);
      } catch (error) {
        console.error("Error parsing citation:", error);
        setSelectedCitation(null);
      }
    } else {
      setSelectedCitation(null);
    }
  }, [sidebarCitation]);

  useEffect(() => {
    async function resolveAndSetMetadata() {
      if (chat?.metadata) {
        await resolveMetadata(
          chat.metadata.companyIds || "",
          chat.metadata.filingIds || "",
          chat.metadata.transcriptIds || "",
        );
      }
    }
    resolveAndSetMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat?.metadata]);

  useEffect(() => {
    if (chat?.metadata) {
      setResolvedMetadata(cache);
    }
  }, [chat?.metadata, cache]);

  const handleContextComplete = async (contextData: any) => {
    const companyIds = contextData.companies?.map((c: any) => c.id) || [];
    const filingIds =
      contextData.documents
        ?.filter((d: any) => d.type === "filing")
        .map((d: any) => d.id) || [];
    const transcriptIds =
      contextData.documents
        ?.filter((d: any) => d.type === "transcript")
        .map((d: any) => d.id) || [];
    const urls =
      contextData.uploadedItems
        ?.filter((item: any) => item.type === "url")
        .map((item: any) => item.url) || [];
    const files =
      contextData.uploadedItems
        ?.filter((item: any) => item.type === "file" && item.file)
        .map((item: any) => item.file) || [];

    try {
      await sendUserInputV2(
        "Update context",
        companyIds,
        filingIds,
        transcriptIds,
        files,
        contextData.includeNews,
        contextData.includeWeb,
        urls,
      );
      setShowAddContext(false);
      getChat(); // Refresh chat data
    } catch (error) {
      console.error("Error updating context:", error);
    }
  };

  if (!chatId) {
    return null;
  }

  const context = {
    companyIds: chat?.metadata?.companyIds || "",
    filingIds: chat?.metadata?.filingIds || "",
    transcriptIds: chat?.metadata?.transcriptIds || "",
    uploadedFiles: chat?.userContent || [],
    useNews: chat?.metadata?.useNews || false,
    useInternet: chat?.metadata?.useInternet || false,
    resolvedMetadata,
  };

  return (
    <div className="min-h-screen bg-background flex">
      <div
        className={cn(
          "flex-grow flex flex-col min-h-screen",
          selectedCitation
            ? selectedCitation.sourceType === CitationSourceType.Estimates
              ? "max-w-[55%] px-6"
              : "max-w-[67%] px-6"
            : "container mx-auto transition-[margin] duration-300 ease-in-out delay-[200ms]",
        )}
      >
        <div
          className={cn(
            "w-full h-full flex flex-col transition-[max-width] duration-200 ease-in-out",
            selectedCitation ? "" : "max-w-full",
          )}
        >
          {/* Sticky Header */}
          <div className="sticky top-0 z-10 bg-background pt-6 pb-4 border-b">
            <BackToStartButton />

            {/* Header Content */}
            <div className="flex items-center justify-between mt-6 mb-4">
              <h1 className="text-2xl font-semibold text-gray-900 max-w-[80%] line-clamp-2">
                {chatTitle}
              </h1>
              <Button
                size="lg"
                className="bg-gray-900 hover:bg-gray-800 text-white shadow-md hover:shadow-lg
                         transition-all duration-200 gap-2 h-11"
                onClick={() => navigate(`/c/${chatId}/report`)}
              >
                <FileDown className="h-5 w-5" />
                Convert to Report
              </Button>
            </div>

            {/* Research Context */}
            <ResearchContext
              context={context}
              onAddContext={() => setShowAddContext(true)}
            />
          </div>

          {/* Main Content */}
          <div className="flex-1 flex flex-col">
            <Card className="flex-1 flex flex-col overflow-hidden">
              <ExistingChat
                chatId={chatId}
                onCitationClick={(citation) => {
                  setSelectedCitation(new CitationIndex(0, citation));
                }}
              />
            </Card>
          </div>
        </div>
      </div>

      {/* Add Context Modal */}
      <AddContextModal
        open={showAddContext}
        onClose={() => setShowAddContext(false)}
        onComplete={handleContextComplete}
        initialNews={chat?.metadata?.useNews || false}
        initialWeb={chat?.metadata?.useInternet || false}
        isExistingChat={true}
      />

      {/* Citation Panel */}
      <div
        className={cn(
          "fixed right-0 top-0 h-screen bg-background transition-transform duration-200 ease-in-out",
          selectedCitation ? "translate-x-0" : "translate-x-full w-0",
          selectedCitation?.sourceType === CitationSourceType.Estimates
            ? "w-[45%]"
            : "w-[33%]",
        )}
      >
        {selectedCitation && (
          <div className="h-full p-6">
            <Card className="h-full overflow-hidden">
              <div className="h-full p-6 overflow-auto">
                <CitationOverview
                  citation={selectedCitation}
                  close={() => setSelectedCitation(null)}
                  chatId={chatId}
                />
              </div>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}
