import { useGetConsensusEstimates } from "../../../../shared/api/useGetConsensusEstimates";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import CompanyLink from "../../../sources/components/CompanyLink";
import { EstimatesTable } from "../../../sources/components/EstimatesTable";

interface SidebarEstimateCitationProps {
  citation: CitationIndex;
}

export function SidebarEstimateCitation({
  citation,
}: SidebarEstimateCitationProps) {
  const { estimates, getEstimates, isLoading } = useGetConsensusEstimates();
  const memoizedCitation = useMemo(() => citation, [citation]);

  const { fiscalPeriod, year, period, quarter, companyId } = useMemo(() => {
    const fiscalPeriod = memoizedCitation.chunkId!;
    const year = parseInt(fiscalPeriod.slice(-4));
    const period = fiscalPeriod.slice(0, 2) === "FY" ? "Annual" : "Quarterly";
    const quarter =
      period === "Quarterly" ? parseInt(fiscalPeriod.slice(1, 2)) : undefined;
    const companyId = parseInt(memoizedCitation.documentId);
    return { fiscalPeriod, year, period, quarter, companyId };
  }, [memoizedCitation]);

  useEffect(() => {
    getEstimates(companyId, year, year, period);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <div className="flex flex-col h-full">
      {isLoading && <CenterLoader />}
      {estimates && estimates.length === 0 && (
        <p className="text-sm leading-relaxed break-words">
          No consensus estimates available for this period.
        </p>
      )}
      {estimates && estimates.length > 0 && (
        <>
          <div className="flex flex-col gap-4">
            <p className="text-sm font-bold leading-relaxed break-words">
              {`${estimates[0].companyName} consensus estimates for ${fiscalPeriod}`}
            </p>
            <CompanyLink
              companyId={companyId}
              companyName={estimates[0].companyName}
            />
          </div>
          <div className="flex-grow mt-4 min-h-0">
            <ScrollArea className="h-full">
              <div className="pr-4">
                <EstimatesTable
                  estimates={
                    period === "Annual"
                      ? estimates
                      : estimates.filter((e) => e.fiscalQuarter === quarter)
                  }
                  isOnHoverCard={false}
                />
              </div>
            </ScrollArea>
          </div>
        </>
      )}
    </div>
  );
}
