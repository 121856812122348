import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";
import { IconFileTypePdf } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useGetChatUserContent } from "../../../sources/api/useGetChatUserContent";
import ScrollableTextWithHighlighting from "../../../../shared/components/scrollable-text-highlighting/ScrollableTextWithHighlighting";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";

interface SidebarUserContentProps {
  chatId: string;
  contentId: string;
  chunkText?: string;
}

export default function SidebarUserContent({
  chatId,
  contentId,
  chunkText,
}: SidebarUserContentProps) {
  const [open, setOpen] = useState(false);
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);
  const { userContents, getUserContents } = useGetChatUserContent();

  useEffect(() => {
    getUserContents(chatId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const userContent = useMemo(() => {
    if (userContents)
      return userContents.find((content) => content.id === contentId);
  }, [contentId, userContents]);

  return userContent ? (
    <>
      <div onClick={() => setOpen(true)} className="cursor-pointer">
        <div className="flex flex-col gap-1.5 w-full">
          <div className="flex items-center gap-2">
            <IconFileTypePdf
              className="text-primary"
              size={isLargeScreen ? 20 : 18}
            />
            <span className="flex-1 text-sm font-bold text-primary underline whitespace-normal break-words leading-relaxed">
              {userContent.title}
            </span>
          </div>
        </div>
      </div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent
          className={cn(
            "max-w-[320px] sm:max-w-[646px] lg:max-w-[910px] xl:max-w-[1200px]",
            "p-0",
          )}
        >
          <DialogHeader>
            <DialogTitle className="text-sm font-bold px-4 py-2">
              {userContent.title}
            </DialogTitle>
          </DialogHeader>
          <div className="px-8 pb-8">
            <ScrollArea className="h-[60vh]">
              <ScrollableTextWithHighlighting
                fullText={userContent.content}
                textToHighlight={chunkText}
              />
            </ScrollArea>
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <p className="text-sm font-bold leading-relaxed break-words">
      Source document not found.
    </p>
  );
}
