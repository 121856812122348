import { IconComponents } from "@tabler/icons-react";
import { useLocalStorage } from "@mantine/hooks";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";
import { cn } from "@/lib/utils";

interface CompanyLinkProps {
  companyName: string;
  companyId: number;
}

export default function CompanyLink({
  companyName,
  companyId,
}: CompanyLinkProps) {
  const [, setSidebarCompanyId] = useLocalStorage<string>({
    key: LocalStorageKeys.SidebarCompanyId,
    defaultValue: "",
  });

  const handleClick = () => {
    setSidebarCompanyId(() => companyId.toString());
  };

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <div className="flex gap-1.5 items-center">
      <IconComponents className="text-primary" size={isLargeScreen ? 20 : 16} />
      <button
        onClick={handleClick}
        className={cn(
          "text-primary underline hover:text-primary/90",
          isLargeScreen ? "text-sm" : "text-xs",
          "leading-none",
        )}
      >
        {companyName}
      </button>
    </div>
  );
}
