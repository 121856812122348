import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";
import { CitationIndex } from "../../sources/models/Citation";
import { useMemo } from "react";
import { IconX } from "@tabler/icons-react";
import { SidebarDocumentCitation } from "./sources-sidebar/SidebarDocumentCitation";
import { CitationSourceType } from "../../../shared/enums/CitationSourceType";
import { SidebarNewsCitation } from "./sources-sidebar/SidebarNewsCitation";
import { SidebarEstimateCitation } from "./sources-sidebar/SidebarEstimateCitation";
import { SidebarUserContentCitation } from "./sources-sidebar/SidebarUserContentCitation";
import { SidebarWebCitation } from "./sources-sidebar/SidebarWebCitation";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";

interface CitationOverviewProps {
  citation: CitationIndex;
  close: () => void;
  chatId: string;
}

export function CitationOverview({
  citation,
  close,
  chatId,
}: CitationOverviewProps) {
  const [, , clearSidebarCitation] = useLocalStorage<CitationIndex>({
    key: LocalStorageKeys.SidebarCitation,
  });
  const memoizedCitation = useMemo(() => citation, [citation]);

  const handleClose = () => {
    clearSidebarCitation();
    close();
  };

  function selectCitationComponent() {
    switch (memoizedCitation.sourceType) {
      case CitationSourceType.News:
        return <SidebarNewsCitation citation={memoizedCitation} />;
      case CitationSourceType.Filing:
      case CitationSourceType.Transcript:
        return <SidebarDocumentCitation citation={memoizedCitation} />;
      case CitationSourceType.Estimates:
        return <SidebarEstimateCitation citation={memoizedCitation} />;
      case CitationSourceType.UserContent:
        return (
          <SidebarUserContentCitation
            citation={memoizedCitation}
            chatId={chatId}
          />
        );
      case CitationSourceType.Internet:
        return (
          <SidebarWebCitation citation={memoizedCitation} chatId={chatId} />
        );
    }
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-end mb-2">
        <Button variant="ghost" size="icon" onClick={handleClose}>
          <IconX className="h-4 w-4" />
        </Button>
      </div>
      <ScrollArea className="flex-grow">{selectCitationComponent()}</ScrollArea>
    </div>
  );
}
