import { useNavigate } from "react-router-dom";
import { Button } from "./button";

export function BackToStartButton() {
  const navigate = useNavigate();

  return (
    <Button
      variant="ghost"
      onClick={() => navigate("/")}
      className="absolute top-4 left-4"
    >
      ← Back
    </Button>
  );
}
