export class WebResult {
  id?: string;
  title: string;
  url: string;
  description: string;
  language: string;
  age?: string;

  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.url = data.url;
    this.description = data.description;
    this.language = data.language;
    this.age = data.age;
  }
}
