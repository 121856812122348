import { IconNews, IconWorldSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";
import { CitationSourceType } from "../../../../shared/enums/CitationSourceType";

interface SidebarWebLinkProps {
  title: string;
  url: string;
  source: string;
  sourceType: CitationSourceType;
}

export default function SidebarWebLink({
  title,
  url,
  source,
  sourceType,
}: SidebarWebLinkProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="block w-full"
    >
      <div className="flex flex-col gap-1.5 w-full">
        <div className="flex items-center gap-2">
          {sourceType === CitationSourceType.Internet && (
            <IconWorldSearch
              stroke={1.5}
              className="text-primary"
              size={isLargeScreen ? 20 : 16}
            />
          )}
          {sourceType === CitationSourceType.News && (
            <IconNews
              stroke={1.5}
              className="text-primary"
              size={isLargeScreen ? 20 : 16}
            />
          )}
          <span className="flex-1 text-sm font-bold text-primary underline whitespace-normal break-words leading-relaxed">
            {source}
          </span>
        </div>
        <div className="flex">
          <span className="flex-1 text-sm font-normal text-primary underline whitespace-normal break-words leading-relaxed mt-1.5">
            {title}
          </span>
        </div>
      </div>
    </a>
  );
}
