import { useGetNews } from "../../../sources/api/useGetNews";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import SidebarWebLink from "./SidebarWebLink";
import { CitationSourceType } from "../../../../shared/enums/CitationSourceType";

interface SidebarNewsCitationProps {
  citation: CitationIndex;
}

export function SidebarNewsCitation({ citation }: SidebarNewsCitationProps) {
  const { news, getNews, isLoading } = useGetNews();
  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getNews(memoizedCitation.documentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      {news && (
        <>
          <SidebarWebLink
            title={news.title}
            url={news.sourceUrl}
            source={news.source}
            sourceType={CitationSourceType.News}
          />
          <ScrollArea className="h-[300px] mt-5">
            <p className="text-sm leading-relaxed break-words">
              {news.summary}
            </p>
          </ScrollArea>
        </>
      )}
    </>
  );
}
