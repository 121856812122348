import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { WebResult } from "../models/WebResult";

export const useGetWebResult = () => {
  const {
    data: webResult,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<WebResult>((response) => new WebResult(response));

  const getWebResult = async (chatId: string, webResultId: string) => {
    await call(`chat/${chatId}/webResult/${webResultId}`, "GET");
  };

  return { webResult, getWebResult, isLoading, isFailed };
};
