export class User {
  public id: string;
  public email: string;
  public customLogoPath: string;
  public creditLimit: number;
  public creditsUsed: number;
  public hasOnboarded: boolean;

  public get creditsRemaining(): number {
    const creditsRemaining = this.creditLimit - this.creditsUsed;
    return creditsRemaining < 0 ? 0 : Math.round(creditsRemaining);
  }

  public get creditsUsedPercentage(): number {
    return (this.creditsUsed / this.creditLimit) * 100;
  }

  constructor(user: any) {
    this.id = user.id;
    this.email = user.email;
    this.customLogoPath = user.customLogoPath;
    this.creditLimit = user.creditLimit;
    this.creditsUsed = user.creditsUsed;
    this.hasOnboarded = user.hasOnboarded;
  }
}
