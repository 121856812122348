import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PlaybookList from "../features/playbook/components/PlaybookList";
import { PlaybookShort } from "../features/playbook/model/Playbook";
import { AddContextModal } from "../features/chat-management/components/AddContextModal";
import { useCreatePlaybookChat } from "../features/playbook/api/useCreatePlaybookChat";
import { CompanyDocumentType } from "@/shared/enums/CompanyDocumentType";
import { CompanyDocumentView } from "@/shared/models/CompanyDocument";

export default function Playbooks() {
  const navigate = useNavigate();
  const [activePlaybook, setActivePlaybook] = useState<PlaybookShort>();
  const [isDefault, setIsDefault] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const { createChat, chat } = useCreatePlaybookChat();

  useEffect(() => {
    if (!chat) return;
    navigate(`/c/${chat.id}`);
  }, [chat, navigate]);

  const handlePlaybookSelect = (
    playbook: PlaybookShort | undefined,
    isDefault: boolean,
  ) => {
    setActivePlaybook(playbook);
    setIsDefault(isDefault);
    if (playbook) {
      setShowContext(true);
    }
  };

  const handleContextComplete = async (contextData: any) => {
    setShowContext(false);
    if (!activePlaybook) return;

    console.log("Context data received:", contextData);
    console.log("Documents:", contextData.documents);

    const transcripts = contextData.documents?.filter(
      (d: CompanyDocumentView) => d.type === CompanyDocumentType.Transcript,
    );
    const filings = contextData.documents?.filter(
      (d: CompanyDocumentView) => d.type === CompanyDocumentType.Filing,
    );

    console.log("Filtered transcripts:", transcripts);
    console.log("Filtered filings:", filings);
    console.log(
      "Document types present:",
      contextData.documents?.map((d: CompanyDocumentView) => d.type),
    );

    await createChat(
      activePlaybook.id,
      isDefault,
      activePlaybook.title,
      false, // sendPdf
      contextData.companies?.map((c: { id: string }) => c.id) || [],
      filings?.map((d: CompanyDocumentView) => d.id) || [],
      transcripts?.map((d: CompanyDocumentView) => d.id) || [],
      contextData.uploadedItems
        ?.filter(
          (i: { type: string; file?: File }) => i.type === "file" && i.file,
        )
        .map((i: { file: File }) => i.file) || [],
      contextData.includeNews,
      contextData.includeWeb,
    );
  };

  return (
    <>
      <PlaybookList
        setActivePlaybook={handlePlaybookSelect}
        setIsDefault={setIsDefault}
      />
      {showContext && (
        <AddContextModal
          open={showContext}
          onClose={() => setShowContext(false)}
          onComplete={handleContextComplete}
          isPlaybook={true}
        />
      )}
    </>
  );
}
