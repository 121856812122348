import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Check, X } from "lucide-react";
import { GeneratedPlaybook } from "../models/GeneratedPlaybook";

interface PlaybookPreviewModalProps {
  open: boolean;
  playbook: GeneratedPlaybook;
  onClose: () => void;
  onConfirm: () => void;
}

export function PlaybookPreviewModal({
  open,
  playbook,
  onClose,
  onConfirm,
}: PlaybookPreviewModalProps) {
  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-xl">
            Preview Generated Playbook
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <div>
            <h3 className="font-semibold text-lg">{playbook.title}</h3>
            <p className="text-sm text-muted-foreground mt-1">
              {playbook.description}
            </p>
          </div>

          <div className="space-y-2">
            <h4 className="font-medium">Steps</h4>
            <ScrollArea className="h-[300px] rounded-md border p-4">
              <div className="space-y-4">
                {playbook.steps.map((step, index) => (
                  <div key={step.id} className="space-y-1">
                    <h5 className="font-medium">
                      {index + 1}. {step.title}
                    </h5>
                    <p className="text-sm text-muted-foreground pl-6">
                      {step.prompt}
                    </p>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </div>
        </div>

        <DialogFooter className="gap-2">
          <Button variant="outline" onClick={onClose} className="gap-2">
            <X className="h-4 w-4" />
            Regenerate
          </Button>
          <Button onClick={onConfirm} className="gap-2">
            <Check className="h-4 w-4" />
            Create Playbook
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
