import { useApiPrivateRequest } from "@/shared/hooks/useApi";
import { GeneratedPlaybook } from "../models/GeneratedPlaybook";

export const useGeneratePlaybook = () => {
  const {
    data: generatedPlaybook,
    call,
    isLoading: isGenerating,
    isFailed: isGenerateFailed,
  } = useApiPrivateRequest<GeneratedPlaybook>(
    (response) => new GeneratedPlaybook(response),
  );

  const generatePlaybook = async (title: string, description: string) => {
    await call(`/generation/generatePlaybook`, "POST", undefined, {
      title,
      description,
    });
  };

  return {
    generatedPlaybook,
    generatePlaybook,
    isGenerating,
    isGenerateFailed,
  };
};
