import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

export const useCreatePlaybookChat = () => {
  const { data: chat, call } = useApiPrivateRequest<ChatShort>(
    (response) => new ChatShort(response),
  );
  const createChat = async (
    playbookId: string,
    isDefaultPlaybook: boolean,
    playbookTitle: string,
    sendPdf: boolean,
    companyIds?: number[],
    filingIds?: string[],
    transcriptIds?: string[],
    files?: File[],
    useNews?: boolean,
    useInternet?: boolean,
    urls?: string[],
  ) => {
    const formatData = new FormData();
    formatData.append("PlaybookId", playbookId);
    formatData.append("IsDefaultPlaybook", isDefaultPlaybook.toString());
    formatData.append("PlaybookTitle", playbookTitle);
    if (companyIds && companyIds.length > 0) {
      for (let i = 0; i < companyIds.length; i++) {
        formatData.append(`CompanyIds`, companyIds[i].toString());
      }
    }
    if (filingIds && filingIds.length > 0) {
      for (let i = 0; i < filingIds.length; i++) {
        formatData.append(`FilingIds`, filingIds[i]);
      }
    }
    if (transcriptIds && transcriptIds.length > 0) {
      for (let i = 0; i < transcriptIds.length; i++) {
        formatData.append(`TranscriptIds`, transcriptIds[i]);
      }
    }
    if (files && files.length > 0) {
      files.forEach((file) => formatData.append("Files", file));
    }
    if (urls && urls.length > 0) {
      urls.forEach((url) => formatData.append("FileUrls", url));
    }
    if (useNews !== undefined) {
      formatData.append("UseNews", useNews.toString());
    }
    if (useInternet !== undefined) {
      formatData.append("UseInternet", useInternet.toString());
    }
    await call("/chat/playbook", "POST", { sendPdf }, formatData);

    sendEvent(EventName.ChatCreated, {
      [EventParameter.ChatMode]: EventTitle.ChatModeCompanyDocsV2,
    });
  };

  return { chat, createChat };
};
