import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook, PlaybookStepShort } from "../model/Playbook";

export const useUpdatePlaybook = () => {
  const {
    data: updatedPlaybook,
    call,
    isLoading: isUpdating,
    isFailed: isUpdateFailed,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const updatePlaybook = async (
    id: string,
    title: string,
    steps: PlaybookStepShort[],
    description?: string,
  ) => {
    console.log("Updating playbook:", { id, title, steps });
    const result = await call(`/playbook/${id}`, "PUT", undefined, {
      title,
      steps,
      description,
    });
    console.log("Update result:", result);
    return result;
  };

  return {
    updatedPlaybook,
    updatePlaybook,
    isUpdating,
    isUpdateFailed,
  };
};
